/* You can add global styles to this file, and also import other style files */
@import "node_modules/primeicons/primeicons";
@import "node_modules/primeng/resources/primeng.min";
@import "node_modules/primeflex/primeflex";

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--surface-100);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--surface-300);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--surface-400);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

html,
body {
  font-family: var(--font-family);
  background-color: var(--surface-b);
  color: var(--text-color);
  padding: 0;
  margin: 0;
}

html {
  font-size: 12px;
}

.login-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.top-loading {
  position: fixed;
  height: 3px;
  top: 0;
  width: 100%;
  z-index: 10000;

  .p-progressbar {
    height: 2px;
  }

  .p-progressbar-value {
    background: var(--primary-color);
  }
}

.state-404 {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  background-image: url("https://http.dog/404.jpg");
}

.web-layout {
  padding-top: 3px;

  .sidebar-layout {
    background-color: var(--surface-a);
    border-right: 1px solid var(--surface-d);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 240px;

    .logo {
      height: 64px;
      background-image: url("./assets/images/logo.png");
      background-size: auto 36px;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.3s ease-out;

      a {
        display: flex;
        height: 100%;
        width: 100%;
      }
    }

    .logo2 {
      height: 64px;
      background-image: url("./assets/images/logo.png");
      background-size: auto 36px;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.3s ease-out;

      a {
        display: flex;
        height: 100%;
        width: 100%;
      }
    }

    .sidebar-toggle {
      background-color: var(--surface-a);
      border: 1px solid var(--surface-d);
      position: absolute;
      top: 20px;
      right: -12px;
      width: 24px;
      height: 24px;
      border-radius: 100px;
      align-items: center !important;
      justify-content: center !important;
      display: flex;
      cursor: pointer;
      visibility: hidden;

      .pi-angle-left {
        display: block;
      }

      .pi-angle-right {
        display: none;
      }
    }

    .sidebar-toggle:hover {
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
      color: var(--primary-color-text);
    }

    .sidebar-menu {
      overflow: hidden;

      .menu-category {
        text-transform: uppercase;
        color: var(--text-color-secondary);
        border-top: 1px solid var(--surface-d);
        padding: 12px;
        cursor: pointer;

        i.icon {
          margin-right: 12px;
          font-size: 160%;
        }

        &.active {
          background-color: var(--surface-d);
          border-right: 3px solid var(--primary-color);
        }
      }

      .menu-category:hover {
        background-color: var(--surface-c);
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        overflow: hidden;

        li {
          padding: 0;
          margin: 0;

          a {
            text-decoration: none;
            padding: 8px 0px 8px 44px;
            display: flex;
            color: var(--text-color);
            cursor: pointer;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .expanded {
              display: block;
            }

            .collapsed {
              display: none;
            }
          }

          a:hover {
            background-color: var(--surface-c);
          }

          a.active {
            background-color: var(--surface-d);
            border-right: 3px solid var(--primary-color);
          }
        }
      }
    }

    .user {
      background-color: var(--surface-b);
      border-top: 1px solid var(--surface-d);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 6px 0px;

      .user-button {
        padding: 12px;
        cursor: pointer;

        i {
          margin-right: 12px;
          font-size: 160%;
        }
      }
    }

    &.collapsed {
      .logo {
        background-size: auto 17px;
        background-position: left;
        margin-left: 4px;
      }

      ul {
        li {
          a {
            align-items: center !important;
            justify-content: center !important;
            padding-left: 0px;

            .expanded {
              display: none;
            }

            .collapsed {
              display: block;
            }
          }
        }
      }

      .sidebar-toggle {
        .pi-angle-left {
          display: none;
        }

        .pi-angle-right {
          display: block;
        }
      }

      .user {
        .user-button {
          padding-right: 0;
          padding-left: 0;

          i {
            margin-right: 0;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  .sidebar-layout:hover {
    .sidebar-toggle {
      visibility: visible;
    }
  }

  .main-layout {

    .table-card {
      padding: 1rem 1rem 0 1rem;

      .selected-columns {
        min-width: 200px;
      }

      .p-datatable-thead {
        tr:first-child {
          background-color: var(--surface-a);
          position: sticky;
          top: 0px;
          z-index: 1;

          th {
            border-top: none;
            border-bottom: none;
          }

          th::before,
          th::after {
            content: "";
            background-color: var(--surface-border);
            position: absolute;
            height: 1px;
            left: 0;
            right: 0;
          }

          th::before {
            top: 0;
          }

          th::after {
            bottom: 0;
          }
        }

        tr {
          th {
            text-align: center;
          }
        }

        tr:last-child {
          th {
            border-top: none;
          }
        }
      }
    }

    .form-container {
      padding: 0 1.5rem 0 1.5rem;

      .p-avatar-xxl .p-avatar {
        width: 6rem;
        height: 6rem;
        font-size: 3rem;

        .p-avatar-icon {
          font-size: 3rem;
        }
      }

      .main-form {
        box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%);
        border-radius: var(--border-radius);
        margin-bottom: 2rem;
        padding: 2rem;
      }

      .child-form {
        padding: 1rem;

        h2 {
          margin: 0 0 1rem 0;
          font-weight: 500;
          font-size: 15px;
        }

        .multiselect-wrap {
          .p-multiselect-label-container {
            .p-multiselect-label {
              white-space: normal;
            }

            .p-multiselect-token {
              border: 2px var(--surface-overlay) solid;
            }
          }
        }
      }

      .p-tabview {
        .p-tabview-nav {
          background-color: var(--surface-b);

          li .p-tabview-nav-link {
            font-size: 14px;
            font-weight: bold;
            background-color: var(--surface-b);
          }

          li.p-highlight .p-tabview-nav-link {
            background-color: var(--surface-0);
          }
        }

        .p-tabview-panels {
          box-shadow: 0px 0px 0px, 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%);
        }
      }
    }

    .form-container:first-child {
      padding-top: 1.5rem;
    }

    .form-container:last-child {
      padding-bottom: 1.5rem;
    }

  }
}